import 'bootstrap/dist/css/bootstrap.css';
import '../styles/rangeslider.css';
import '../styles/index.scss';
import '../styles/cms_global.scss';
import '../styles/banner_global.scss';
import '../styles/goodCause_global.scss';

import React, {useEffect, useCallback, useState, useMemo} from 'react';
import dynamic from 'next/dynamic';
import {withRouter} from 'next/router';
import Script from 'next/script';

import Layout from '@components/Layout';
import {AppProvider} from '@context/AppProvider';
import SiteMeta from '@components/SiteMeta';
import {getCategories} from '@utils/service';

const NextNProgress = dynamic(() => import('nextjs-progressbar'));
const LockPop = dynamic(() => import('@models/lock/lock.modal'), {ssr: false});
let categoryCache;
setInterval(() => {
	categoryCache = null;
}, 30000);

const Analytics = () => {
	return (
		<>
			<Script
				src='https://www.googletagmanager.com/gtag/js?id=UA-140234527-3'
				strategy='afterInteractive'
			/>
			<Script id='google-analytics' strategy='afterInteractive'>
				{`
				window.dataLayer = window.dataLayer || [];
				function gtag(){window.dataLayer.push(arguments);}
				gtag('js', new Date());
				gtag('config', 'UA-140234527-3');
			`}
			</Script>

			<Script id='gtm-analytics' strategy='afterInteractive'>
				{`
				(function(w, d, s, l, i) {
					w[l] = w[l] || [];
					w[l].push({
						'gtm.start': new Date().getTime(),
						event: 'gtm.js'
					});
					var f = d.getElementsByTagName(s)[0],
						j = d.createElement(s),
						dl = l != 'dataLayer' ? '&l=' + l : '';
					j.async = true;
					j.src =
						'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
					f.parentNode.insertBefore(j, f);
				})(window, document, 'script', 'dataLayer', 'GTM-TZQ4PM6');
			`}
			</Script>
			<Script id='fb-events' strategy='afterInteractive'>
				{`
					! function(f, b, e, v, n, t, s) {
						if (f.fbq) return;
						n = f.fbq = function() {
							n.callMethod ?
								n.callMethod.apply(n, arguments) : n.queue.push(arguments)
						};
						if (!f._fbq) f._fbq = n;
						n.push = n;
						n.loaded = !0;
						n.version = '2.0';
						n.queue = [];
						t = b.createElement(e);
						t.async = !0;
						t.src = v;
						s = b.getElementsByTagName(e)[0];
						s.parentNode.insertBefore(t, s)
					}(window, document, 'script',
						'https://connect.facebook.net/en_US/fbevents.js');
					fbq('init', '753093162224735');
					fbq('track', 'PageView');
			`}
			</Script>
		</>
	);
};

const App = props => {
	const {Component, pageProps, categoryProps} = props;
	const [isLock, setIslock] = useState(process.env.IS_LOCK);
	useEffect(() => {
		categoryCache = categoryProps;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const resetWindowScrollPosition = useCallback(
		() =>
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			}),
		[],
	);

	useEffect(() => {
		window.onbeforeunload = function () {
			resetWindowScrollPosition();
		};
		return () => (window.onbeforeunload = null);
	}, [resetWindowScrollPosition]);

	let meta = pageProps && pageProps.meta ? pageProps.meta : {};
	const lockModel = useMemo(
		() => (
			<LockPop
				callback={() => {
					setIslock(false);
					sessionStorage.setItem('modalLock', true);
				}}
			/>
		),
		[],
	);
	if (
		typeof window !== 'undefined' &&
		!sessionStorage.getItem('modalLock') &&
		isLock === 'true'
	) {
		return lockModel;
	}
	return (
		<SiteMeta meta={meta}>
			{process.env.APP_ENV === 'production' && <Analytics />}
			<AppProvider>
				<NextNProgress
					color='#A9A9A9'
					startPosition={0.3}
					stopDelayMs={200}
					height={3}
					showOnShallow={true}
				/>

				{Component.Layout ? (
					<Layout
						requireAuth={Component.requireAuth}
						hideMenu={Component.HideMenu}
						hideFooter={Component.HideFooter}
						hideGoodCause={Component.HideGoodCause}
						categories={categoryProps}
						hideSearch={Component.HideSearch}
						hideMiddleHeader={Component.HideMiddleHeader}
					>
						<Component {...pageProps} />
					</Layout>
				) : (
					<Component {...pageProps} />
				)}
			</AppProvider>
		</SiteMeta>
	);
};

App.getInitialProps = async () => {
	if (categoryCache) {
		return {categoryProps: categoryCache};
	}
	const categoryProps = await getCategories({});
	categoryCache = categoryProps;

	return {categoryProps};
};

export default withRouter(App);
